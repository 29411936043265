@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.logo {
  /* padding: 5px; */
  text-align: center;
  color: #fff;
  /* height: 32px; */
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}

.logo img {
  height: 75px;
}

.logo .img_collapse {
  height: 36px;
}

.img_footer {
  height: 75px;
  margin-top: -2px;
}

.ant-layout-footer {
  padding: 10px 50px;
}

.grey-back {
  background: #ddd;
}

.card {
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
}

.font-weight-bold {
  font-weight: 500 !important;
  font-size: 13px;
  /* font-size: 0.7rem; */
  /* text-transform: uppercase; */
}

.text-primary {
  color: #1266f1;
}

.add_shipp_box {
  height: 120px;
  background: url("../public/image/card-addd.jpg");
  background-repeat: no-repeat;
  background-size: 300px 120px;
}

.card p {
  font-size: 1.2em;
  font-weight: 700;
  color: #5a5c69 !important;
}

.card {
  border: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 7%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.address,
.user_detail {
  border: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 7%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  border-left: 2px solid #5a5c69;
}

.border-left-primary {
  border-left: 4px solid #002140;
}

.text-primary {
  color: #002140;
}

.border-left-secondary {
  border-left: 4px solid #4f138baf;
}

.text-secondary {
  color: #4f138baf;
}

.border-left-success {
  border-left: 4px solid #019b04;
}

.text-success {
  color: #019b04;
}

.border-left-info {
  border-left: 4px solid #1b74c6;
}

.text-info {
  color: #1b74c6;
}

.border-left-warning {
  border-left: 4px solid #e2ca10;
}

.icon_size {
  font-size: 20px;
  color: #dddfeb !important;
}

.ant-menu-title-content {
  font-weight: 600;
}

.submenu {
  margin: 14px 0px;
}

.form-design {
  padding: 20px;
}

.form-design .ant-col {
  padding: 0px 5px;
}

.form-design h4 {
  font-size: 22px;
  font-weight: 500;
}

.form-design-failed {
  padding: 0px;
}

.form-design-failed .ant-col {
  padding: 0px 2px;
}

/*ant btn secondary design*/
.ant-btn-success {
  color: #fff;
  background: #019b04;
  border-color: #019b04;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #019b04;
  border-color: #019b04;
  background: #fff;
}

.ant-btn-secondary {
  color: #fff;
  border-color: #440f7a;
  background: #440f7a;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #4f138baf;
  border-color: #4f138baf;
  background: #fff;
}

/*ant btn warning design*/
.ant-btn-warning {
  color: #fff;
  border-color: #e2ca10;
  background: #e2ca10;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #e2ca10;
  border-color: #e2ca10;
  background: #fff;
}

.secondary-font-color {
  color: #440f7a;
}

.warning-font-color {
  color: #e2ca10;
}

.ant-form-item-explain-error {
  padding: 2px 0px 8px 0px;
}

.ant-btn-orange {
  color: #fff;
  border-color: #faad14;
  background: #faad14;
}

.ant-btn-orange:hover,
.ant-btn-orange:focus {
  color: #faad14;
  border-color: #faad14;
  background: #fff;
}

.btn-top {
  margin-top: 100px;
}

.ant-btn-dashed-secondary {
  color: #4f138baf;
  border-color: #4f138baf;
  background: #fff;
  border-style: dashed;
}

.my-profile p span {
  padding-left: 10px;
}

.shipping_detail h5 {
  line-height: 1.6rem;
  font-weight: 500;
}

.address,
.user_detail {
  margin: 10px;
  border-radius: 10px;
}

.address h4,
.user_detail h4 {
  margin-bottom: 40px;
}

.address p {
  line-height: 0.9rem;
  margin-left: 10px;
  font-size: 14px;
}

.user_detail p {
  line-height: 0.4rem;
  margin-left: 8px;
  font-size: 12px;
  margin-top: 8px;
}

.user_detail .ant-card-body {
  padding: 8px;
  font-size: 13px;
}

.line_item {
  margin-top: 20px;
}

.rowExp {
  font-size: 15px;
  font-weight: 600;
}

.actionButton {
  width: 180px;
}

.add_shipping_btn {
  width: 97.3%;
  height: 102px;
  margin: 10px;
  border-radius: 10px;
  font-size: 19px;
}

.add_cart_in_btn {
  background-image: linear-gradient(245deg, rgb(149, 110, 73), rgba(149, 110, 73, 0.7));
  width: 97.3%;
  height: 102px;
  margin: 10px;
  border-radius: 10px;
  font-size: 19px;
  border: none;
}

.add_cart_out_btn {
  background-image: linear-gradient(245deg, rgb(43, 131, 126), rgba(43, 131, 126, 0.7));
  width: 97.3%;
  height: 102px;
  margin: 10px;
  border-radius: 10px;
  font-size: 19px;
  border: none;
}

.text-center {
  text-align: center;
}

.p5 {
  padding-left: 5px;
  padding-right: 10px;
}

.title {
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 600;
}

.filter {
  margin: 15px 14px 0px 14px;
}

.mapping_detail label {
  font-weight: 600;
  font-size: 1.1em;
  padding-bottom: 10px;
}

.left_greeting {
  padding: 10px;
  /* color: #3b7bda; */
  font-weight: 600;
}

.left_greeting {
  margin-top: 0px;
  margin-left: 20px;
  font-size: 16px;
}

.item_selected:hover {
  color: #000 !important;
}

.show_box {
  display: block;
  cursor: default;
}

.hide_box {
  display: none;
}

.shipping_status {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;
}

.add_product .ant-form-item,
.add_Cart_in .ant-form-item,
.add_cart_out .ant-form-item {
  padding: 5px;
  margin-bottom: 0px;
}

.add_product .ant-btn-primary,
.add_Cart_in .ant-btn-primary {
  float: right;
}

.stock_box {
  -webkit-box-flex: 1;
  flex-grow: 1;
  background-color: rgb(49, 114, 176);
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 95%;
  height: auto;
  border-radius: 0.25rem;
}

.discre{
  background-color: rgb(217, 17, 17);
}

.stock_box p {
  padding: 12px 16px 0px;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.stock_box .quantity {
  border-top: 1px solid rgb(255, 255, 255);
  padding: 15px;
  line-height: 2;
  color: rgb(255, 255, 255);
}

.add_Cart_in .ant-form-item-label>label {
  font-size: 13px;
  padding: 0px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0px;
}

.cart_box {
  border-radius: 4px;
  background-image: linear-gradient(245deg, rgb(149, 110, 73), rgba(149, 110, 73, 0.7));
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0px;
  -webkit-box-align: center;
  align-items: center;
}

.summary_box {
  display: flex;
  width: 100%;
  padding: 6px 16px;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  /* min-height: 42px; */
}

.summary_box_{
  display: block;
}

.summary_box p {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0px;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  width: 40%;
  flex: 0 0 auto;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  text-overflow: ellipsis;
  /* overflow: hidden; */
  text-rendering: optimizelegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.cart_out_box {
  background-image: linear-gradient(245deg, rgb(43, 131, 126), rgba(43, 131, 126, 0.7));
}

.cart_out_box .summary_box {
  padding: 3px 14px;
}

.warehousing_show_selected .ant-menu-title-content a span {
  display: block;
  font-size: 12px;
  text-align: center;
}

.warehousing_show_selected {
  line-height: 24px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: 50px !important;
}

.warehousepro>.ant-menu-submenu-title,
.warehousepro>.ant-menu-submenu-title:hover {
  background: crimson;
}

.shippro>.ant-menu-submenu-title,
.shippro>.ant-menu-submenu-title:hover {
  background: #440f7a;
}

.help {
  color: #666464;
  font-size: 12px;
  margin-top: -22px;
  margin-bottom: 10px;
  margin-left: 8px;
}

.ship_address .ant-row .ant-form-item-label>label {
  font-size: 12px;
}

.ship_address .ant-form-item {
  margin-bottom: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
}

.dashboard_btn {
  background-image: linear-gradient(245deg, rgb(149, 110, 73), rgba(149, 110, 73, 0.7));
  width: 97.3%;
  height: 250px;
  margin: 10px;
  border-radius: 10px;
  font-size: 19px;
  border: none;
}

.dashboard_box {
  background-image: linear-gradient(90deg, #739dc3, #567087);
  width: 97.3%;
  height: 250px;
  margin: 10px;
  border-radius: 10px;
  font-size: 19px;
  border: none;
}

.about_tool {
  width: 98.6%;
  margin: 10px;
  border-radius: 10px;
  /* border: none; */
}

.dashboard_box .summary_box .box_title {
  margin-top: 12px;
  font-size: 20px;
}

.dashboard_box .summary_box p {
  font-weight: 500;
}

/* .about_tool .ant-card-type-inner .ant-card-head {
  background: "#d9d9d9"
} */

.ant-btn-grad {
  color: #fff;
  border-color: #739dc3;
  background-image: linear-gradient(90deg, #739dc3, #567087);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-grad:hover,
.ant-btn-grad:focus {
  color: #fff;
  border-color: #739dc3;
  background: linear-gradient(90deg, #739dc3, #567087);
}

.shippro_ {
  background-image: linear-gradient(90deg, #8f56ec, #54386f);
}

.pending_task{
  background-image: linear-gradient(90deg, #ecce56, #d9751d); 
  height: auto;
}

.ant-select-item-option-content {
  white-space: inherit;
}

.mark_as_done{
  float: right;
}